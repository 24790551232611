$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-teasercarousel {
  padding: map-get($grid, row-gap) * 2 0;

  @include media('>=desktop') {
    box-shadow: 3px 0 3px 2x rgba(0, 0, 0, .2);
  }
}

.sw-teasercarousel__cnt {
  background: map-get($backgrounds, light-bg);
  display: flex;
  flex-direction: column;

  @include media('>=desktop') {
    display: grid;
    grid-template-columns: 50% (map-get($grid, column-gap) * 3) 1fr map-get(
        $grid,
        column-gap
      );
    grid-template-rows: repeat(3, auto) 1fr auto 10px;
  }

  &::after {
    background: var(--sw-hero-background, currentColor);
    content: '';
    display: block;
    grid-column: 1 / -1;
    grid-row: 6;
    min-height: 7px;
    width: 100%;
  }
}

$mobile-gutter: map-get($grid, column-gap) * 1.5;

.sw-teasercarousel__item {
  display: contents;

  &[aria-hidden='true'] {
    display: none;
  }

  > * {
    margin-top: rem(36px);
  }

  > .image {
    grid-column: 1;
    grid-row: 1 / -1;
    min-height: rem(250);
    position: relative;
    margin-top: 0;
  }

  .cmp-image__image {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .title {
    grid-column: 3;
    grid-row: 2;
    margin-top: rem(60px);

    @include media('<desktop') {
      margin-top: rem(48px);
      padding-left: $mobile-gutter;
      padding-right: $mobile-gutter;
    }
  }

  > .title a {
    color: inherit;
    text-decoration: none;

    &:visited {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:after {
      content: "";
      display: inline-block;
      @include icon-carat-right;
      // using em so that if the user alters the type
      // of heading size the icon will adjust
      height: .7em;
      margin-bottom: -.06em;
      margin-left: .15em;
      width: .7em;
      transition: margin map-get($transitions, default-ease);
    }

    &:hover:after {
      margin-left: .45em;
    }
  }

  > .text {
    grid-column: 3;
    grid-row: 3;
    margin-top: rem(18px);

    @include media('<desktop') {
      padding-left: $mobile-gutter;
      padding-right: $mobile-gutter;
    }
  }

  > .eyebrow {
    color: #006CAD;
    grid-column: 3;
    grid-row: 1;
    margin-top: rem(36px);
    text-transform: uppercase;

    @include media('<desktop') {
      padding-left: $mobile-gutter;
      padding-right: $mobile-gutter;
    }
  }

  > .eyebrow a {
    color: #006CAD;
    text-decoration: none;

    &:visited {
      text-decoration: none;
    }

    &:hover {
      color: #015183;
      text-decoration: underline;
    }
  }

  > .container {
    grid-column: 3;
    grid-row: 4;
    margin: 0;

    > .cmp-container > * {
      margin-top: rem(36px);
    }

    @include media('<desktop') {
      padding-left: $mobile-gutter;
      padding-right: $mobile-gutter;
    }
  }
}

.sw-teasercarousel__actions {
  align-items: center;
  display: flex;
  font-size: rem(14);
  grid-column: 3;
  grid-row: 5;
  justify-content: flex-end;
  padding: (map-get($grid, row-gap) / 2) 0;
  margin-top: rem(36px);

  @include media('<desktop') {
    padding-left: $mobile-gutter;
    padding-right: $mobile-gutter / 2;
  }

  &::before {
    content: attr(data-current) ' / ' attr(data-max);
    order: 1;
    padding: 0 1em;
  }
}

.sw-teasercarousel__btn {
  background: none;
  border: 0;
  padding: 0.5em;
  cursor: pointer;

  .sw-icon {
    stroke-width: 0.5;
  }
}

.sw-teasercarousel__btn--prev {
  order: 0;
}

.sw-teasercarousel__btn--next {
  order: 2;
}
